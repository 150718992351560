import React from 'react'

let vector = [0]

const Contact = function contact ({}){
    return (
        <section className="terms">
            <div className="content colflex">
                <p>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </p>
                <h1 style={{fontWeight:'700'}}>Esta página no ha sido encontrada</h1>
                <a href='/portafolio' className='button'>
                    Ver Portafolio
                </a>
                <p>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </p>
            </div>
        </section>
    )
}

export default Contact