import React from 'react'

let vector = [0]
let validate = 1

let validate_load = 1
var vector_pages = [];

function generateSlug(text) {
    return text
        .normalize("NFD").replace(/[\u0300-\u036f]/g, "") // Quita tildes
        .toLowerCase()
        .replace(/\//g, '_') // Reemplaza '/' por '_'
        .replace(/[^a-z0-9\-_()\s]/g, '') // Permite solo letras, números, guiones, guiones bajos y paréntesis
        .trim()
        .replace(/\s+/g, '-') // Reemplaza todos los espacios por guiones medios
}  

const Products = function products ({
    openModal,categories,
    product,
    prevHandler,nextHandler,currentPage,changeHandler,pages,
    changeCategory,closeAll,closeChangeCategory,
    changeOrder,closeChangeOrder,
    closeChangeSubCategory,closeChangeBrand
    }){

    let pagination = ""
    let page = ""
    let count_pages = 0

    if(parseInt(pages) >= 1){
        let vector_pages = [];
        for (let i = 0; i<pages; i++){
            vector_pages[i] = i
        }
        pagination = vector.map((v)=>{
            return (
                <div className="paginator flex" key={1}>
                    <img onClick={prevHandler} src="/img/products/back.svg" alt="⬅️"/>
                    <div className="numbers flex">
                        {vector_pages.map((v)=>{
                            count_pages = count_pages + 1
                            if(count_pages<=10 && currentPage <= 8){
                                return (
                                    <a 
                                        className={currentPage == v?"selected":""}  
                                        onClick={() => changeHandler(v)} 
                                        key={v}
                                    >
                                        {v+1}
                                    </a>
                                )
                            }else if(count_pages >= 9 && currentPage >= 9){
                                return (
                                    <a 
                                        className={currentPage == v?"selected":""}  
                                        onClick={() => changeHandler(v)} 
                                        key={v}
                                    >
                                        {v+1}
                                    </a>
                                )
                            }
                        })}
                    </div>
                    <img onClick={nextHandler} src="/img/products/next.svg" alt="➡️"/>
                </div>
            )
        })
    }else{
        vector_pages = [];
        vector_pages[0] = 0
    }

    return (
        <section className="products" style={{backgroundImage: `url('img/backgrounds/products.png')`}}>

            <div className="filterProducts">
                <div className="content flex">
                    <a className="flex filter openModalFilter" onClick={()=>openModal()}>
                        <img src="/img/products/filter.svg" alt="icon-filter"/>
                        <p>Filtrar</p>
                    </a>
                    <div className="sliderFilter relative" >
                        <img src="/img/products/fback.svg" alt="⬅️" className="arrow fBack dnone absolute"/>
                        <div className="listFilters" id="slickFilter">
                            {categories.map((c)=>{
                                return(
                                    <div 
                                        className="item colflex" 
                                        key={c.id}
                                        onClick={()=>changeCategory(c.id,c.name)}
                                    >
                                        <img src={c.icon_mobile} alt="icon"/>
                                        <span>{c.name}</span>
                                    </div>
                                )
                            })}
                        </div>
                        <img src="/img/products/fnext.svg" alt="➡️" className="arrow fNext dnone absolute"/>
                    </div>
                    {vector.map((v)=>{
                        if(categories.length > 0 && validate == 1){
                            validate = 2
                            setTimeout(function(){
                                $('#slickFilter').slick({
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    dots: false,
                                    arrows: false,
                                    autoplay: false,
                                    autoplaySpeed: 5000,
                                    mobileFirst: true,
                                    centerMode: false,
                                    variableWidth: true,
                                    responsive: [
                                      {
                                        breakpoint: 760,
                                        settings: {
                                          slidesToShow: 4,
                                          slidesToScroll: 4,
                                        }
                                      },
                                      {
                                        breakpoint: 1020,
                                        settings: {
                                          slidesToShow: 6,
                                          slidesToScroll: 6,
                                        }
                                      },
                                      {
                                        breakpoint: 1279,
                                        settings: {
                                          slidesToShow: 4,
                                          slidesToScroll: 4,
                                          arrows: true,
                                          centerMode: false,
                                          variableWidth: true,
                                          prevArrow: '.fBack',
                                          nextArrow: '.fNext'
                                        }
                                      },
                                  
                                    ]
                                });
                            },100)
                        }
                    })}
                    <a className="flex order relative ">
                        <img src="/img/products/order.svg" alt="icon-order"/>
                        <p>Orden</p>
                        <div className="select absolute ">
                            <div onClick={()=>changeOrder(0,'Defecto')}>Por Defecto</div>
                            <div onClick={()=>changeOrder(1,'A - Z')}>A - Z</div>
                            <div onClick={()=>changeOrder(2,'Z - A')}>Z - A</div>
                        </div>
                    </a>
                </div>
            </div>

            <div className="container colflex">

                <div className="applied flex">
                    <div 
                        className="item delete flex"
                        id="filterTag_all"
                        onClick={()=>closeAll()}
                    >
                        <p>Borrar todos</p>
                        <span>X</span>
                    </div>
                    <div 
                        className="item flex" 
                        id="filterTag_category" 
                    >
                        <p>
                            Categoria 
                            <span id="filterTag_span_category">
                            </span>
                        </p>
                        <span 
                            onClick={()=>closeChangeCategory()}
                        >   
                            X
                        </span>
                    </div>
                    <div 
                        className="item flex" 
                        id="filterTag_subcategory"
                    >
                        <p>
                            Subcategoria
                            <span id="filterTag_span_subcategory">
                            </span>
                        </p>
                        <span 
                            onClick={()=>closeChangeSubCategory()}
                        >
                            X
                        </span>
                    </div>
                    <div 
                        className="item flex "
                        id="filterTag_brand"
                    >
                        <p>
                            Marca
                            <span id="filterTag_span_brand">
                            </span>
                        </p>
                        <span 
                            onClick={()=>closeChangeBrand()}
                        >
                            X
                        </span>
                    </div>
                    <div className="item flex"
                        id="filterTag_order"
                    >
                        <p>
                            Ordernar por 
                            <span id="filterTag_span_order">
                            </span>
                        </p>
                        <span 
                            onClick={()=>closeChangeOrder()}
                        >
                            X
                        </span>
                    </div>
                </div>

                <div className="listProducts grid" id="allProducts">
                    {product.map((p)=>{
                        validate_load = 2
                        let category_v = categories.filter(
                            (v) => (v.id === p.category_id),
                        )[0] || {};
                        const slug = generateSlug(p.name)
                        return(
                            <div className="item" key={p.id}>
                                <a href={"/product/"+slug}  className="thumb flex">
                                    <img src={'/'+p.img} alt="product-image"/>
                                </a>
                                <div className="info colflex">
                                    <img src={category_v.icon_mobile} alt="icon-category"/>
                                    <a href={"/product/"+slug}>
                                        {p.name}
                                    </a>
                                    <a href={"/product/"+slug} className="goProduct grid">
                                        <svg width="24" height="12" viewBox="0 0 28 16" fill="none">
                                            <path d="M27.7071 8.70711C28.0976 8.31658 28.0976 7.68342 27.7071 7.29289L21.3431 0.928931C20.9526 0.538407 20.3195 0.538407 19.9289 0.928931C19.5384 1.31946 19.5384 1.95262 19.9289 2.34314L25.5858 8L19.9289 13.6569C19.5384 14.0474 19.5384 14.6805 19.9289 15.0711C20.3195 15.4616 20.9526 15.4616 21.3431 15.0711L27.7071 8.70711ZM5.18061e-08 9L27 9L27 7L-5.18061e-08 7L5.18061e-08 9Z" fill="#25D366"/>
                                        </svg>  
                                    </a>
                                </div>
                            </div>
                        )
                    })}
                </div>

                {vector.map((v)=>{
                    if(product.length <= 0 && validate_load == 2){
                        return(
                            <div key={0} style={{width: "100%"}}>
                                <h6 style={{textAlign: 'center',fontSize: "20px",margin: "10px 0px",color: "#fff"}}>No hay productos para mostrar</h6>
                            </div>
                        )
                    }
                })}

                {pagination}

            </div>

        </section>
    )
}

export default Products