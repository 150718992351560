import React from 'react'

let vector = [0]

var vector_pages = [];

const Content = function content ({
        url_admin,list,
        prevHandler,nextHandler,currentPage,changeHandler,pages,
        form,onChange,onSubmit,submitClick
    }){
    
    let validate_load = 1

    let pagination = ""
    let page = ""
    let count_pages = 0

    if(parseInt(pages) >= 1){
        let vector_pages = [];
        for (let i = 0; i<pages; i++){
            vector_pages[i] = i
        }
        pagination = vector.map((v)=>{
            return (
                <div className="paginator container flex" key={1}>
                    <img onClick={prevHandler} src="/img/news/back.svg" alt="⬅️"/>
                    <div className="numbers flex">
                        {vector_pages.map((v)=>{
                            count_pages = count_pages + 1
                            if(count_pages<=10 && currentPage <= 8){
                                return (
                                    <span
                                        className={currentPage == v?"selected":""}  
                                        onClick={() => changeHandler(v)} 
                                        key={v}
                                    >
                                        {v+1}
                                    </span>
                                )
                            }else if(count_pages >= 9 && currentPage >= 9){
                                return (
                                    <a 
                                        className={currentPage == v?"selected":""}  
                                        onClick={() => changeHandler(v)} 
                                        key={v}
                                    >
                                        {v+1}
                                    </a>
                                )
                            }
                        })}
                    </div>
                    <img onClick={nextHandler} src="/img/news/next.svg" alt="➡️"/>
                </div>
            )
        })
    }else{
        vector_pages = [];
        vector_pages[0] = 0
    }

    return (
        <section className="files">
            <div className="container colflex" id="allProducts">
                <form 
                    id="formLogin" 
                    action="" 
                    onSubmit={onSubmit} 
                    className="colflex"
                >
                    <div className="search flex">
                        <label>Buscar:</label>
                            <input
                                name="name"
                                placeholder="Archivo..."
                                type="search"
                                value={form.name}
                                onChange={onChange}
                            />
                    </div>
                </form>
                <div className="listFiles colflex">

                    {list.map((l)=>{
                        validate_load = 2
                        const formattedDate = new Date(l.created_at).toLocaleDateString(); // Formato de fecha
                        return(
                            <div className="item flex" key={l.id}>
                                <p>{l.name} - {formattedDate}</p>
                                <a className="flex" href={url_admin+l.file} download target='_blank'>
                                    <span>Descargar</span>
                                    <svg width="20" height="16" viewBox="0 0 24 20" fill="none">
                                    <g clipPath="url(#clip0_326_232)">
                                        <path d="M10.4785 8.78873L9.39686 7.70704C8.92362 7.2338 8.11235 7.2338 7.63911 7.70704C7.16587 8.18028 7.16587 8.99155 7.63911 9.46479L11.1546 12.9803C11.6278 13.4535 12.4391 13.4535 12.9123 12.9803L16.4278 9.46479C16.9011 8.99155 16.9011 8.18028 16.4278 7.70704C15.9546 7.2338 15.1433 7.2338 14.6701 7.70704L12.98 9.39718V1.28451C12.98 0.946479 12.8447 0.608451 12.6419 0.405634C12.3715 0.135211 12.0335 0 11.6954 0C11.0194 0 10.4785 0.540845 10.4785 1.2169V8.78873Z" fill="white"/>
                                        <path d="M19.538 19.4027H4.46197C2.02817 19.4027 0 17.3746 0 14.9408V7.30133C0 6.55767 0.608451 5.94922 1.35211 5.94922C2.09577 5.94922 2.70423 6.55767 2.70423 7.30133V14.9408C2.70423 15.8872 3.51549 16.6985 4.46197 16.6985H19.538C20.4845 16.6985 21.2958 15.8872 21.2958 14.9408V7.30133C21.2958 6.55767 21.9042 5.94922 22.6479 5.94922C23.3915 5.94922 24 6.55767 24 7.30133V14.9408C24 17.3746 21.9718 19.4027 19.538 19.4027Z" fill="white"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_326_232">
                                        <rect width="24" height="19.4028" fill="white"/>
                                        </clipPath>
                                    </defs>
                                    </svg>
                                </a>
                            </div>
                        )
                    })}

                    {vector.map((v)=>{
                        if(list.length <= 0 && validate_load == 1){
                            return(
                                <div key={0} style={{width: "100%"}}>
                                    <h6 style={{textAlign: 'center',fontSize: "17px",margin: "10px 0px",color: "#000",fontWeight: "400",marginBottom: '20%'}}>No se encontraron archivos</h6>
                                </div>
                            )
                        }
                    })}
                    
                </div>

                {pagination}

            </div>
        </section>
    )
}

export default Content