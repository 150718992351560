import React from 'react'

let vector = [0]

const Header = function header ({info}){
    return (
        <div>
            <header id="header">
                <div className="container flex">
                    <a href={'/'} className="logo flex">
                        <img src="/img/logo.svg" alt="RadioComunicaciones"/>
                    </a>
                    <nav >
                        <a href={'/'}>Inicio</a>
                        <a href={'/nosotros'}>Nosotros</a>
                        <a href={'/portafolio'}>Portafolio</a>
                        <a href={'/contacto'}>Contacto</a>
                        <a href={'/acceder'}>Login</a>
                    </nav>
                    <div className="social">
                        <a href={info.instagram} target='_blank' className="flex">
                            <img src="/img/insta.svg" alt="Ig-icon"/>
                        </a>
                        <a href={info.facebook} target='_blank'  className="flex">
                            <img src="/img/face.svg" alt="Fb-icon"/>
                        </a>
                    </div>
                    <a href={'tel:'+info.phone_one} target='_blank' className='phone-mob'><img src="/img/icon-phone.svg"/> </a>
                    <img src="/img/menu.svg" alt="menu" className="menu" id="nav-toggle"/>
                </div>
            </header>
            <div className="separatorHeader" id="separatorHeader">
            </div>
        </div>
    )
}

export default Header