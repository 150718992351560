import React,{useRef,Component} from 'react';
import { createRoot } from "react-dom/client";

import Header from "../components/header";
import Menu from "../components/menu";
import Footer from "../components/footer";

import Info from "../components/product/info";
import MenuFixed from "../components/product/menufixed";
import ModalShare from "../components/product/modalshare";
import Gallery from "../components/product/gallery";

const rootElement = document.getElementById("content_product");
const root = createRoot(rootElement);

export default class Product extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'product',
            info:[],
            product:[],
            category:[],
            images:[],
            error: null,
        }

        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.closeModal_2 = this.closeModal_2.bind(this)

        this.copyUrl = this.copyUrl.bind()

        this.openGallery = this.openGallery.bind()

    }

    openModal = async (id) =>{
        $('#modalShare').css('display','inline-block')
    }

    closeModal = async (e) =>{
        if (document.getElementById('modalShare_m').contains(e.target)){
        }else{
            $('#modalShare').css('display','none')
        }
    }

    closeModal_2 = async () =>{
        $('#modalShare').css('display','none')
    }

    copyUrl(){
        var $temp = $("<input>");
        var $url = $(location).attr('href');

        $("body").append($temp);
        $temp.val($url).select();
        document.execCommand("copy");
        $temp.remove();
        
        swal({
            title: "¡Excelente!",
            text: "¡Enlace Copiado!",
            icon: imageURL,
        });
    }

    openGallery(){
        document.getElementById("image_first").click();
    }

    async componentDidMount(){
        try{
            let res = await fetch('/get/product/'+product_id)
            let data = await res.json()
            this.setState({
                info:data[0],
                product:data[1],
                category:data[2],
                images:data[3]
            })
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    render() {
        return (
            <div>
                <Header
                    info={this.state.info}
                />
                <Menu
                    info={this.state.info}
                />
                <main>
                    <Info
                        openModal={this.openModal}
                        openGallery={this.openGallery}
                        category={this.state.category}
                        product={this.state.product}
                        images={this.state.images}
                        info={this.state.info}
                    />
                    <MenuFixed
                        info={this.state.info}
                        product={this.state.product}
                    />
                    <ModalShare
                        closeModal={this.closeModal}
                        copyUrl={this.copyUrl}
                        closeModal_2={this.closeModal_2}
                        openGallery={this.openGallery}
                    />
                    <Gallery
                        product={this.state.product}
                        images={this.state.images}
                    />
                </main>
                <Footer
                    info={this.state.info}
                />
            </div>
        );
    }

}

if (document.getElementById('content_product')) {
    root.render(
        <Product />
    )
}