if(window.location.pathname.replace(https,'') == "/"){
    require('./view/index');
}
if(window.location.pathname.replace(https,'') == "/nosotros"){
    require('./view/about');
}
if(window.location.pathname.replace(https,'') == "/contacto"){
    require('./view/contact');
}
if(window.location.pathname.replace(https,'') == "/portafolio"){
    require('./view/portfolio');
}
if(window.location.pathname.replace(https,'') == "/portafolio/filtros"){
    require('./view/portfolio');
}
if(window.location.pathname.replace(https,'').includes("/producto/")){
    require('./view/product');
}
if(window.location.pathname.replace(https,'').includes("/product/")){
    require('./view/product');
}
if(window.location.pathname.replace(https,'') == "/terminos-y-condiciones"){
    require('./view/terms');
}

if(window.location.pathname.replace(https,'') == "/acceder"){
    require('./view/login');
}
if(window.location.pathname.replace(https,'') == "/archivos"){
    require('./view/files');
}