import React from 'react'

let vector = [0]
let validate = 1

const Info = function info ({
    openModal,openGallery,
    category,product,images,info
    }){
    const handleWhatsappClick = (e) => {
        e.preventDefault();
        
        const whatsappUrl = `https://api.whatsapp.com/send/?phone=${info.whatsapp}&text=Hola, me gustaria cotizar sobre el siguiente producto ${product.name}`;
        window.open(whatsappUrl, '_blank');
        gtag_report_conversion(whatsappUrl);
    };
    return (
        <section className="infoProduct">

            <div className="backDesk dnone">
                <a href={'/portafolio'} className="flex">
                    <img src="/img/products/backb.svg" alt="⬅️"/>
                </a>
                <span>Catálogo/{category.name}</span>
            </div>

            <div className="container colflex">
                <div className="backPortfolio flex">
                    <a href={'/portafolio'} className="flex backButton">
                        <img src="/img/products/pback.svg" alt="⬅️"/>
                        <span>Atrás</span>
                    </a>
                    <a 
                        onClick={()=>openModal()} 
                        className="openModalShare flex"
                    >
                        <img src="/img/products/share.svg" alt=""/>
                    </a>
                </div>
                <div className="infoLeft">
                    <h1>{product.name}</h1>
                    <h2>Ref. {product.reference}</h2>
                    <div className="gallery relative">
                        <a 
                            className="absolute flex" 
                            onClick={()=>openGallery()}
                        >
                            <img src="/img/products/allicon.svg" alt="📷"/>
                            Ver todas
                        </a>
                        <div className="sliderBig" id="sliderProductBig">
                            <img src={"/"+product.img} alt="image-product"/>
                            {images.map((i)=>{
                                let url_image = i.img.replace('https://admin.radiocomunicaciones.co/','')
                                return(
                                    <img src={"/"+url_image} key={i.id} alt="image-product"/>
                                )
                            })}
                        </div>
                        <div className="asnavSliderBig" id="asnavSliderBig">
                            <img src={"/"+product.img} alt="image-product"/>
                            {images.map((i)=>{
                                let url_image = i.img.replace('https://admin.radiocomunicaciones.co/','')
                                return(
                                    <img src={"/"+url_image} key={i.id} alt="image-product"/>
                                )
                            })}
                        </div>
                        {vector.map((v)=>{
                            if(images.length > 0 && validate == 1){
                                validate = 2
                                setTimeout(function(){
                                    $('#sliderProductBig').slick({
                                        slidesToShow: 1,
                                        slidesToScroll: 1,
                                        arrows: false,
                                        fade: true,
                                        asNavFor: '#asnavSliderBig'
                                    });
                                    $('#asnavSliderBig').slick({
                                        slidesToShow: 4,
                                        slidesToScroll: 1,
                                        asNavFor: '#sliderProductBig',
                                        dots: false,
                                        //centerMode: true,
                                        arrows: false,
                                        focusOnSelect: true,
                                        mobileFirst: true,
                                        responsive: [
                                          {
                                            breakpoint: 1020,
                                            settings: {
                                              slidesToShow: 4,
                                            }
                                          },
                                        ]
                                    });
                                },100)
                            }else if(validate == 1){
                                    validate = 3
                                    console.log("validate 3")
                            }else if(validate == 3){
                                validate = 4
                                console.log("validate 4")
                                setTimeout(function(){
                                    $('#sliderProductBig').slick({
                                        slidesToShow: 1,
                                        slidesToScroll: 1,
                                        arrows: false,
                                        fade: true,
                                        asNavFor: '#asnavSliderBig'
                                    });
                                    $('#asnavSliderBig').slick({
                                        slidesToShow: 4,
                                        slidesToScroll: 1,
                                        asNavFor: '#sliderProductBig',
                                        dots: false,
                                        //centerMode: true,
                                        arrows: false,
                                        focusOnSelect: true,
                                        mobileFirst: true,
                                        responsive: [
                                          {
                                            breakpoint: 1020,
                                            settings: {
                                              slidesToShow: 4,
                                            }
                                          },
                                        ]
                                    });
                                },100)
                            }
                        })}
                    </div>
                </div>

                <div className="details colflex">

                    <h1>{product.name}</h1>
                    <h2>Ref. {product.reference}</h2>
                    <p dangerouslySetInnerHTML={{__html: product.description}}></p>

                    <div className="features flex">
                        {vector.map((v)=>{
                            if(product.link_pdf != null && product.link_pdf != ""){
                                return(
                                    <div className="item flex" key={v}>
                                        <div className="info colflex">
                                            <img src="/img/products/feature.svg" alt="📕"/>
                                            <span>Ficha <br/>Técnica</span>
                                        </div>
                                        <a href={"/"+product.link_pdf} className="grid" target='_blank'>
                                            <img src="/img/products/fright.svg" alt="➡️"/>
                                        </a>
                                    </div>
                                )
                            }
                        })}
                        {vector.map((v)=>{
                            if(product.link_video != null && product.link_video != ""){
                                return(
                                    <div className="item flex" key={v}>
                                        <div className="info colflex">
                                            <img src="/img/products/video.svg" alt="📽️"/>
                                            <span>Video del <br/>Producto</span>
                                        </div>
                                        <a href={product.link_video}  className="grid">
                                            <img src="/img/products/fright.svg" alt="➡️"/>
                                        </a>
                                    </div>
                                )
                            }
                        })}
                    </div>

                    <div className="actions flex">
                        <a href={"https://api.whatsapp.com/send/?phone="+info.whatsapp+'&text=Hola, me gustaria cotizar sobre el siguiente producto '+product.name} target='_blank' className="flex" onClick={handleWhatsappClick}>
                            <span>Contactar</span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <g clipPath="url(#clip0_29_848)">
                            <path d="M10 0C4.55882 0 0.0735294 4.48529 0.0735294 9.92647C0.0735294 11.6912 0.514706 13.3824 1.39706 14.9265L0 20.0735L5.29412 18.6765C6.76471 19.4853 8.38235 19.8529 10.0735 19.8529C15.5147 19.8529 20 15.3676 20 9.92647C20 7.27941 18.9706 4.77941 17.0588 2.86765C15.1471 1.02941 12.6471 0 10 0ZM10 18.1618C8.52941 18.1618 7.05882 17.7941 5.80882 16.9853L5.51471 16.8382L2.35294 17.6471L3.16176 14.6324L2.94118 14.3382C2.13235 13.0147 1.69118 11.4706 1.69118 9.92647C1.69118 5.36765 5.36765 1.69118 9.92647 1.69118C12.1324 1.69118 14.1912 2.57353 15.7353 4.11765C17.2794 5.66176 18.1618 7.72059 18.1618 9.92647C18.2353 14.4853 14.5588 18.1618 10 18.1618Z" fill="#25D366"></path>
                            <path d="M14.5588 11.9853C14.3382 11.8382 13.0882 11.25 12.8676 11.1765C12.6471 11.1029 12.5 11.0294 12.2794 11.3235C12.1324 11.5441 11.6176 12.1323 11.4706 12.2794C11.3235 12.4265 11.1765 12.5 10.9559 12.3529C10.7353 12.2059 9.92647 11.9853 8.97059 11.1029C8.23529 10.4412 7.72059 9.63234 7.57353 9.41175C7.42647 9.19116 7.57353 9.0441 7.64706 8.89704C7.94118 8.60293 8.16176 8.23528 8.23529 8.01469C8.30882 7.86763 8.30882 7.72057 8.23529 7.57352C8.16176 7.42646 7.64706 6.24999 7.5 5.73528C7.35294 5.2941 7.13235 5.36763 6.98529 5.36763C6.83824 5.36763 6.69118 5.36763 6.54412 5.36763C6.39706 5.36763 6.10294 5.44116 5.88235 5.66175C5.58824 5.88234 5 6.47057 5 7.72057C5 8.97057 5.88235 10.147 6.02941 10.2941C6.17647 10.4412 7.79412 12.9412 10.2941 14.0441C10.8824 14.2647 11.3235 14.4853 11.6912 14.5588C12.2794 14.7794 12.7941 14.7059 13.2353 14.6323C13.6765 14.5588 14.7059 14.0441 14.9265 13.4559C15.1471 12.8676 15.1471 12.3529 15.0735 12.2794C14.9265 12.2059 14.7794 12.1323 14.5588 11.9853Z" fill="#25D366"></path>
                            </g>
                            <defs>
                            <clipPath id="clip0_29_848">
                            <rect width="19.9265" height="20" fill="white"></rect>
                            </clipPath>
                            </defs>
                            </svg>
                        </a>
                        <a href={"tel:"+info.whatsapp} className="flex">
                            <span>Llamar</span>
                            <svg width="14" height="20" viewBox="0 0 14 20" fill="none">
                            <g clipPath="url(#clip0_29_854)">
                            <path d="M12.732 15.7731L11.9588 14.2783C11.3918 13.402 10.3094 12.9896 9.27843 13.2989L8.3506 13.6082C8.09286 13.7113 7.78359 13.5567 7.62895 13.2474L5.3609 7.9381C5.25781 7.68036 5.41245 7.37109 5.67018 7.26799C6.8042 6.75253 7.52585 5.61851 7.47431 4.32985L7.37121 2.06181C7.31967 1.44325 7.01039 0.824695 6.49493 0.46387C5.97946 0.103045 5.30936 -0.0515937 4.6908 0.103045L4.07225 0.206138C1.34029 0.876241 -0.360745 3.50511 0.0516262 6.23707C0.309358 7.835 0.721729 10.2577 1.54647 12.3195C2.68049 15.1546 3.81451 16.6494 5.15472 18.3505C5.51554 18.8144 5.97946 19.2268 6.49493 19.4845C7.62895 20.103 9.07225 20.1546 10.5155 19.5876C10.5671 19.5876 10.5671 19.5876 10.6186 19.536L11.9588 18.8144C12.0104 18.8144 12.0104 18.7628 12.0619 18.7628C13.0413 18.0927 13.2991 16.8041 12.732 15.7731ZM11.1856 17.5257L9.94853 18.1958C8.55678 18.7113 7.16503 18.402 6.39183 17.4226C5.30936 16.134 4.12379 14.6391 2.98977 11.8041C2.21657 9.89686 1.8042 7.62882 1.59802 6.08243C1.28874 4.02057 2.52585 2.21645 4.43307 1.70098L5.05163 1.54634C5.20627 1.4948 5.36091 1.54634 5.464 1.59789C5.51554 1.59789 5.51554 1.64944 5.56709 1.64944C5.67018 1.70098 5.77328 1.85562 5.82482 2.11335L5.92792 4.3814C5.97946 4.99995 5.61864 5.56696 5.05163 5.77315C4.0207 6.23707 3.50523 7.37109 3.91761 8.45356L6.18565 13.8144C6.59802 14.8969 7.73204 15.4123 8.81451 15.103L9.74235 14.7938C10.1032 14.6907 10.464 14.8453 10.6186 15.103L11.3918 16.5463C11.598 16.8556 11.4949 17.268 11.1856 17.5257Z" fill="#00E0FF"></path>
                            </g>
                            <defs>
                            <clipPath id="clip0_29_854">
                            <rect width="13.0412" height="20" fill="white"></rect>
                            </clipPath>
                            </defs>
                            </svg>
                        </a>
                        <a 
                            onClick={()=>openModal()} 
                            className="openModalShare flex"
                        >
                            <img src="/img/products/share.svg" alt=""/>
                        </a>
                    </div>

                </div>

            </div>

        </section>
    )
}

export default Info