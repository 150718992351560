import React,{useRef,Component} from 'react';
import { createRoot } from "react-dom/client";

import Header from "../components/header";
import Menu from "../components/menu";
import Footer from "../components/footer";

import Content from "../components/404/content";

const rootElement = document.getElementById("content_404");
const root = createRoot(rootElement);

export default class V404 extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: '404',
            url_admin:[],
            info:[],
            error: null,
            loading: 0,
        }

        this._recaptchaRef = React.createRef()

    }

    async componentDidMount(){
        try{
            let res = await fetch('/get/home')
            let data = await res.json()

            this.setState({
                url_admin:data[0],
                info:data[0],
            })
            if(data[1].length > 0){
                setTimeout(()=>{
                    this.setState({
                        loading: 1,
                    })
                },2000)
            }
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    render() {
        return (
            <div>
                <div className={'loading '+(this.state.loading == 1?'loading_none':'')}>
                    <div>
                        <img 
                            className="pulse animated infinite" 
                            src='/img/logo.svg'
                        />
                    </div>
                </div>
                <Header
                    info={this.state.info}
                />
                <Menu
                    info={this.state.info}
                />
                <main>
                    <Content
                    />
                </main>
                <Footer
                    info={this.state.info}
                />
            </div>
        );
    }

}

if (document.getElementById('content_404')) {
    root.render(
        <V404 />
    )
}