import React from 'react'

let vector = [0]
let count = 0

const Modal = function modal ({
        closeModal,categories,subcategories,brands,
        changeCategory,changeSubCategory,changeBrand,
        category_id
    }){
    return (
        <div className="jquery-modal blocker current" style={{
            display: 'none'
        }}>
            <div 
                className="modal modalFilter" 
                id="modalFilter" 
                style={{opacity: 1,display: "inline-block"}}
            >
                <div className="content colflex">

                    <div className="tittle flex">
                        <h2>Filtros</h2>
                        <a className="flex absolute" onClick={()=>closeModal()}>
                            <img src="/img/products/close.svg" alt="Cerrar"/>
                        </a>
                    </div>

                    <div className="contentFilters colflex">

                        {categories.map((v)=>{
                            count = count + 1
                            return(
                                <div key={v.id}>
                                    <div className="accTittle flex" onClick={()=>changeCategory(v.id,v.name)}>
                                        <h4>{v.name}</h4>
                                        <img src="/img/products/down.svg" alt="⬇️" className="rotater"/>
                                    </div>
                                    <div className={"accContent openedContent accContent_cat_"+v.id} id={"accContent_"+count}>
                                        <div className="selectors flex">
                                            {subcategories.map((s)=>{
                                                if(v.id == category_id){
                                                return(
                                                    <label 
                                                        key={s.id} 
                                                        onClick={()=>changeSubCategory(s.id,s.name)}
                                                    >
                                                        {s.name}
                                                        <input 
                                                            type="checkbox"  name="sub_checkbox" id={"sub_"+s.id}
                                                        />
                                                    </label>
                                                )
                                                }
                                            })}
                                        </div>
                                        <div className="brands colflex">
                                            <h5>Marca</h5>
                                            <div className="selectors flex">
                                                {brands.map((b)=>{
                                                    if(v.id == category_id){
                                                    return(
                                                        <label 
                                                            key={b.id}
                                                            onClick={()=>changeBrand(b.id,b.name)}
                                                        >
                                                            {b.name}
                                                            <input
                                                                type="checkbox" 
                                                                name="brand_checkbox"
                                                                id={"brand_"+b.id}    
                                                            />
                                                        </label>
                                                    )
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>

                    <div className="apply grid">
                        <a onClick={()=>closeModal()}>aplicar</a>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Modal